import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  sendContactUsForm: {
    url: 'prospect-request/get-in-touch/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      email: [NetworkParamType.string],
      first_name: [NetworkParamType.string],
      last_name: [NetworkParamType.string],
      interests: [NetworkParamType.string],
      other_comment: [NetworkParamType.string],
      entry_point_url: [NetworkParamType.string],
      entry_point_name: [NetworkParamType.string],
      is_payment: [NetworkParamType.boolean],
      attachments: [NetworkParamType.filesArray],
      subscribe_to_newsletter: [NetworkParamType.boolean],
      request_info: [NetworkParamType.string],
      additional_info: [NetworkParamType.string],
      call_schedule_datetime: [NetworkParamType.string],
    },
  },
  updateContactUsForm: {
    url: 'prospect-request/{id}/update/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      interests: [NetworkParamType.string],
      other_comment: [NetworkParamType.string],
      attachments: [NetworkParamType.filesArray],
    },
  },
  updateOrCreateContactUsForm: {
    url: 'prospect-request/update-or-create/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      email: [NetworkParamType.string],
      call_schedule_datetime: [NetworkParamType.string],
    },
  },
  uploadAttachment: {
    url: 'prospect-request-attachment/upload/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    authorization: true,
    params: {
      attachment: [NetworkParamType.file],
    },
  },
  sendSupportFormInstitutional: {
    url: 'support-form/institutional/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      email: [NetworkParamType.string],
      first_name: [NetworkParamType.string],
      last_name: [NetworkParamType.string],
      comment: [NetworkParamType.string],
      attachments: [NetworkParamType.filesArray],
    },
  },
  sendSupportFormMCR: {
    url: 'support-form/mcr/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    multipart: true,
    params: {
      help_type: [NetworkParamType.string],
      comment: [NetworkParamType.string],
      attachments: [NetworkParamType.filesArray],
    },
  },
};
